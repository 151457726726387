export const shuffleArray = <T>(arr: T[]) => {
  const newArr = [...arr]

  for (let i = newArr.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    const tmp = newArr[i]
    newArr[i] = newArr[j]
    newArr[j] = tmp
  }

  return newArr
}
